import React, { createElement as h } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import MeaningsOfColors from "./docs/MeaningsOfColors";
import Settings from "./docs/Settings";
import Terminal from "./Terminal";
import Navbar from "./Navbar";
import AppInfo from "./AppInfo";

interface Props {
  isLogin: boolean;
}

export default function App(props: Props) {
  const { isLogin } = props;

  return (
    <div id="docs">
      <HashRouter>
        <div>
          <Navbar />
          {isLogin
            ? (
              <Routes>
                <Route path="/" element={null} />
                <Route
                  path="/stats"
                  element={<AppInfo isLogin={isLogin} className="doc" />}
                />
                <Route path="/search" element={null} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/tags" element={null} />
                <Route path="/terminal" element={<Terminal />} />
                <Route path="/terminal/:id" element={<Terminal />} />
                <Route path="/theme" element={null} />
                <Route path="/help" element={<MeaningsOfColors />} />
              </Routes>
            )
            : <AppInfo isLogin={isLogin} className="doc" />}
        </div>
      </HashRouter>
    </div>
  );
}

/*
      <Story />
      <Examples />
      <Download />
      <Examples />
      <HowItWorks />
      <MeaningsOfColors />
      <Stats />
      <About />
          <Route path="/story" component={Story} />
          <Route path="/examples" component={Examples} />
          <Route path="/download" component={Download} />
          <Route path="/examples" component={Examples} />
          <Route path="/how-it-works" component={HowItWorks} />
          <Route path="/about" component={About} />
*/
