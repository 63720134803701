import React from "react";

interface Props {
  className?: string;
  src: string;
}

const AsyncImage = (props: Props) => {
  const [loadedSrc, setLoadedSrc] = React.useState("");
  React.useEffect(() => {
    setLoadedSrc("");
    if (props.src) {
      const handleLoad = () => {
        setLoadedSrc(props.src);
      };
      const image = new Image();
      image.addEventListener("load", handleLoad);
      image.src = props.src;
      return () => {
        image.removeEventListener("load", handleLoad);
      };
    }
  }, [props.src]);
  if (loadedSrc === props.src) {
    return <img {...props} />;
  }
  return null;
};

export default AsyncImage;
