import React, { createElement as h } from "react";
import { BrowserRouter, HashRouter, Link, Route } from "react-router-dom";
import { useToggle } from "react-use";
import Sidebar from "./Sidebar";
import Settings from "./docs/Settings";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faHashtag,
  faHome,
  faInfoCircle,
  faPalette,
  faQuestionCircle,
  faSearch,
  faTerminal,
} from "@fortawesome/free-solid-svg-icons";

export default function App() {
  return (
    <nav className="doc" id="topbar">
      <Menu className="menu" />
      <Link to="/" className="tit">
        <Home />
      </Link>
      <Link to="/stats" className="tit">
        <Info />
      </Link>
      <Link to="/theme" className="tit">
        <Theme />
      </Link>
      <Link to="/terminal" className="tit">
        <Terminal />
      </Link>
      <Link to="/tags" className="tit">
        <Tags />
      </Link>
      <Link to="/search" className="tit">
        <Search />
      </Link>
      <Link to="/help" className="help">
        <Help />
      </Link>
    </nav>
  );
}

interface Props {
  className?: string;
  sidebarRef?: React.RefObject<HTMLDivElement>;
}

function Menu({ className = "" }: Props) {
  const [open, toggle] = useToggle(false);
  let sidebarRef = React.useRef<HTMLDivElement | null>(null);
  return (
    <>
      <FontAwesomeIcon className={className} icon={faBars} onClick={toggle} />
      {open && (
        <Sidebar onClose={() => {}} position="left" sidebarRef={sidebarRef}>
          <Settings className="" />
        </Sidebar>
      )}
    </>
  );
}

function Help() {
  return <FontAwesomeIcon icon={faQuestionCircle} />;
}

function Home() {
  return <FontAwesomeIcon icon={faHome} />;
}

function Info() {
  return <FontAwesomeIcon icon={faInfoCircle} />;
}

function Theme() {
  return <FontAwesomeIcon icon={faPalette} />;
}

function Terminal() {
  return <FontAwesomeIcon icon={faTerminal} />;
}

function Search() {
  return <FontAwesomeIcon icon={faSearch} />;
}

function Tags() {
  return <FontAwesomeIcon icon={faHashtag} />;
}
