import React, { useEffect } from "react";
import Docs from "./Docs";
import AgentList from "./AgentList";
import ProjectList from "./ProjectList";
import { useHub } from "./HubContext";
import usePWAInstall from "use-pwa-install";

export function App() {
  const { wc, dot, ens, sol, agents, theme, toggleTheme } = useHub();
  const isLogin = !!(wc || dot || ens || sol);
  const { isInstalled, install } = usePWAInstall();

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <div id="main" className={theme}>
      <button
        id="theme"
        onClick={toggleTheme}
        style={{
          position: "fixed",
          top: "0",
          right: "0",
          fontSize: "2rem",
          border: "none",
          background: "none",
          cursor: "pointer",
          outline: "none",
        }}
      >
        {theme === "light" ? "🌞" : "🌚"}
      </button>
      <div id="controller">
        <input id="api" defaultValue="https://libredot.com" />
        <button id="list">List</button>
        <button id="watch">Watch</button>
      </div>
      <div style={{ display: "none" }}>
        {isInstalled
          ? <p>App is installed</p>
          : <button onClick={install}>Install</button>}
      </div>
      <div id="view">
        {/*isLogin ?  <Docs /> : <></> */}
        <Docs isLogin={isLogin} />
        {/*<AppInfo isLogin={isLogin} className="agent" />*/}
        <AgentList isLogin={isLogin} agents={agents} />
        <ProjectList />
        {/*<Footer />*/}
        {/*<div id="agents"></div>*/}
      </div>
    </div>
  );
}

export default React.memo(App);

/*
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'UA-137403717-1');
  </script>
*/
