import React, { createElement as h } from "react";
import Project, { IProject } from "./Project";
import { useHub } from "./HubContext";

interface Props {
}

export function App(props: Props) {
  const { login, logout } = useHub();

  return (
    <div className="project-list agent">
      {projects.slice().reverse().map((x: IProject) => (
        <Project
          {...x}
          key={x.name}
        />
      ))}
    </div>
  );
}

/*
<h1>All Projects</h1>
<div class="project-list">

  <a href="/p/angle" aria-label="Project angle">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra/OpenGL%20ES_RGB_June16.svg" />

      <div class="project-label">angle</div>
    </div>
  </a>

  <a href="/p/art" aria-label="Project art">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/art-logo.png" />

      <div class="project-label">art</div>
    </div>
  </a>

  <a href="/p/boringssl" aria-label="Project boringssl">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra/boringssl-logo.png" />

      <div class="project-label">boringssl</div>
    </div>
  </a>

  <a href="/p/celab" aria-label="Project celab">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/celab.png" />

      <div class="project-label">celab</div>
    </div>
  </a>

  <a href="/p/chromium" aria-label="Project chromium">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/chromium.svg" />

      <div class="project-label">chromium</div>
    </div>
  </a>

  <a href="/p/chromium-m100" aria-label="Project chromium-m100">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/chromium.svg" />

      <div class="project-label">chromium-m100</div>
    </div>
  </a>

  <a href="/p/chromium-m102" aria-label="Project chromium-m102">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/chromium.svg" />

      <div class="project-label">chromium-m102</div>
    </div>
  </a>

  <a href="/p/chromium-m103" aria-label="Project chromium-m103">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/chromium.svg" />

      <div class="project-label">chromium-m103</div>
    </div>
  </a>

  <a href="/p/chromium-m104" aria-label="Project chromium-m104">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/chromium.svg" />

      <div class="project-label">chromium-m104</div>
    </div>
  </a>

  <a href="/p/chromium-m96" aria-label="Project chromium-m96">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/chromium.svg" />

      <div class="project-label">chromium-m96</div>
    </div>
  </a>

  <a href="/p/chromium-m97" aria-label="Project chromium-m97">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/chromium.svg" />

      <div class="project-label">chromium-m97</div>
    </div>
  </a>

  <a href="/p/chromium-website" aria-label="Project chromium-website">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/chromium.svg" />

      <div class="project-label">chromium-website</div>
    </div>
  </a>

  <a href="/p/cq-test" aria-label="Project cq-test">
    <div class="project">

        <div class="project-favicon-text">cq</div>

      <div class="project-label">cq-test</div>
    </div>
  </a>

  <a href="/p/crashpad" aria-label="Project crashpad">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/crashpad-logo.svg" />

      <div class="project-label">crashpad</div>
    </div>
  </a>

  <a href="/p/crosvm" aria-label="Project crosvm">
    <div class="project">

        <div class="project-favicon-text">cr</div>

      <div class="project-label">crosvm</div>
    </div>
  </a>

  <a href="/p/dart" aria-label="Project dart">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/dartlang.png" />

      <div class="project-label">dart</div>
    </div>
  </a>

  <a href="/p/dawn" aria-label="Project dawn">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/dawn-logo.png" />

      <div class="project-label">dawn</div>
    </div>
  </a>

  <a href="/p/devtools-frontend" aria-label="Project devtools-frontend">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/devtools.svg" />

      <div class="project-label">devtools-frontend</div>
    </div>
  </a>

  <a href="/p/emscripten-releases" aria-label="Project emscripten-releases">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/emscripten.svg" />

      <div class="project-label">emscripten-releases</div>
    </div>
  </a>

  <a href="/p/flutter" aria-label="Project flutter">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/flutter-logo.svg" />

      <div class="project-label">flutter</div>
    </div>
  </a>

  <a href="/p/fuchsia" aria-label="Project fuchsia">
    <div class="project">

        <img src="https://storage.googleapis.com/fuchsia-infra/logo/fuchsia_mark_black.svg" />

      <div class="project-label">fuchsia</div>
    </div>
  </a>

  <a href="/p/gn" aria-label="Project gn">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/gn-logo.png" />

      <div class="project-label">gn</div>
    </div>
  </a>

  <a href="/p/goma-client" aria-label="Project goma-client">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/goma-logo.png" />

      <div class="project-label">goma-client</div>
    </div>
  </a>

  <a href="/p/goma-server" aria-label="Project goma-server">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/goma-server-logo.png" />

      <div class="project-label">goma-server</div>
    </div>
  </a>

  <a href="/p/infra" aria-label="Project infra">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/chrome-infra-logo-200x200.png" />

      <div class="project-label">infra</div>
    </div>
  </a>

  <a href="/p/libyuv" aria-label="Project libyuv">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/libyuv-logo.png" />

      <div class="project-label">libyuv</div>
    </div>
  </a>

  <a href="/p/nacl" aria-label="Project nacl">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/nacl-logo.png" />

      <div class="project-label">nacl</div>
    </div>
  </a>

  <a href="/p/node-ci" aria-label="Project node-ci">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/v8.svg" />

      <div class="project-label">node-ci</div>
    </div>
  </a>

  <a href="/p/open-codecs" aria-label="Project open-codecs">
    <div class="project">

        <img src="https://storage.googleapis.com/downloads.webmproject.org/media/images/oc-logo-luci.svg" />

      <div class="project-label">open-codecs</div>
    </div>
  </a>

  <a href="/p/openscreen" aria-label="Project openscreen">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/openscreen-logo.png" />

      <div class="project-label">openscreen</div>
    </div>
  </a>

  <a href="/p/pdfium" aria-label="Project pdfium">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra/pdfium-logo.png" />

      <div class="project-label">pdfium</div>
    </div>
  </a>

  <a href="/p/pigweed" aria-label="Project pigweed">
    <div class="project">

        <div class="project-favicon-text">pi</div>

      <div class="project-label">pigweed</div>
    </div>
  </a>

  <a href="/p/r8" aria-label="Project r8">
    <div class="project">

        <div class="project-favicon-text">r8</div>

      <div class="project-label">r8</div>
    </div>
  </a>

  <a href="/p/tint" aria-label="Project tint">
    <div class="project">

        <div class="project-favicon-text">ti</div>

      <div class="project-label">tint</div>
    </div>
  </a>

  <a href="/p/v8" aria-label="Project v8">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra-public/logo/v8.svg" />

      <div class="project-label">v8</div>
    </div>
  </a>

  <a href="/p/webrtc" aria-label="Project webrtc">
    <div class="project">

        <img src="https://storage.googleapis.com/chrome-infra/webrtc-logo-vert-retro-255x305.png" />

      <div class="project-label">webrtc</div>
    </div>
  </a>

</div>


    <footer>
      <hr>
        <div><img class="lucy-logo" src="https://storage.googleapis.com/chrome-infra/lucy-small.png"></div>
        <div>
          <a href="https://chromium.googlesource.com/infra/luci">LUCI</a><br>
          built: <b><span class="local-time " data-timestamp="1657536267333">Monday, 11-Jul-22 10:44:27 UTC</span></b><br>
          version: <b>11620-32edf62</b><br>
        </div>
    </footer>
  </div>
</body>
<script>
$(function () {
  'use strict';

  setTimeout(function() {
    milo.makeTimesLocal();
    milo.annotateDurations();
  }, 0);
  $(document).tooltip({
    show: false,
    hide: false
  });
});
</script>
<script>
  (function(i,s,o,g,r,a,m){i['CrDXObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://storage.googleapis.com/chops-feedback/feedback.js','crdx');

  crdx('setFeedbackButtonLink', 'https://bugs.chromium.org/p/chromium/issues/entry?components=Infra%3ELUCI%3EUserInterface');

(function(window) {
  let lastWindowScrollTop = window.scrollY;

  function debounce(f, wait) {
    let timeout;
    return function(...args) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        f(...args);
        timeout = null;
      }, wait);
    };
  }

  window.addEventListener('scroll', debounce(function(evt) {
    const delta = window.scrollY - lastWindowScrollTop;
    lastWindowScrollTop = window.scrollY;
    const absDelta = Math.abs(delta);
    const category = window.location.pathname.split('/').slice(0, 2).join('/');
    ga('send', 'event', category, 'scroll-abs', '', absDelta);
    if (delta > 0) {
      ga('send', 'event', category, 'scroll-down', '', absDelta);
    } else {
      ga('send', 'event', category, 'scroll-up', '', absDelta);
    }
  }, 250));
})(window);
</script>

<script>
setTimeout(function() {
	(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
	(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
	m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
	})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

	ga('create', 'UA-55762617-24', 'auto');
	ga('send', 'pageview');
}, 0);
</script>

</html>
*/

export default React.memo(App);

const projects: IProject[] = [
  {
    "label": "Echo",
    "name": "echo",
    "href": "https://echo.ufo.k0s.io",
    "img": "/echo.svg",
  },
  {
    "label": "Clear",
    "name": "clear",
    "href": "/clear.html",
    "img": "/clear.svg",
  },
  // websocket/http echo server
  {
    "label": "Deno",
    "name": "deno",
    "href": "/#/p/deno",
    "img": "https://upload.wikimedia.org/wikipedia/commons/8/84/Deno.svg",
  },
  {
    "label": "Alpine",
    "name": "alpine",
    "href": "/#/p/alpine",
    "img":
      "https://gitlab.alpinelinux.org/uploads/-/system/appearance/favicon/1/alpinelinux-logo-icon-grey.png",
  },
  {
    "label": "Arch Linux",
    "name": "archlinux",
    "href": "/#/p/archlinux",
    "img": "https://www.svgrepo.com/show/353422/archlinux.svg",
  },
  {
    "label": "Clash",
    "name": "clash",
    "href": "/#/p/clash",
    "img": "https://upload.wikimedia.org/wikipedia/commons/0/0b/Clash_Logo.png",
  },
  {
    "label": "Subshell",
    "name": "subshell",
    "href": "/#/p/subshell",
    "img": "https://subshell.xyz/favicon.ico",
  },
  {
    "label": "Jsproxy",
    "name": "jsproxy",
    "href": "/#/p/jsproxy",
    "img":
      "https://upload.wikimedia.org/wikipedia/commons/9/99/Unofficial_JavaScript_logo_2.svg",
  },
  {
    "label": "Whois",
    "name": "whois",
    "href": "https://whois.ufo.k0s.io",
    "img": "/whois.svg",
  },
  {
    "label": "Crates.io",
    "name": "crates",
    "href": "https://github.ufo.k0s.io/rust-lang/crates.io-index",
    "img": "/cargo.png",
  },
  {
    "label": "Rust Reference",
    "name": "cargo-docs",
    "href": "https://rust.up.railway.app",
    "img": "/rust.svg",
  },
  {
    "label": "Go Doc",
    "name": "godoc",
    "href": "/#/p/godoc",
    "img": "/gopher.svg",
  },
  {
    "label": "Wolfram",
    "name": "wolfram",
    "href": "/#/p/wolfram",
    "img": "/wolfram.svg",
  },
  {
    "label": "Firefox",
    "name": "firefox",
    "href": "/#/p/firefox",
    "img": "/firefox.svg",
  },
  {
    "label": "Zig",
    "name": "zig",
    "href": "/#/p/zig",
    "img": "/zig.svg",
  },
  {
    "label": "IP",
    "name": "ip",
    "href": "https://ip.ufo.k0s.io",
    "img": "/ip.svg",
  },
  {
    "label": "Podman",
    "name": "podman",
    "href": "https://podman.ufo.k0s.io",
    "img": "/podman.svg",
  },
  {
    "label": "Powershell",
    "name": "powershell",
    "href": "/#/p/powershell",
    "img": "/powershell.svg",
  },
  {
    "label": "Rclone",
    "name": "rclone",
    "href": "https://rclone.ufo.k0s.io",
    "img": "/rclone.svg",
  },
  /*
  {
    "label": "Telegraf",
    "name": "telegraf",
    "href": "https://telegraf.ufo.k0s.io",
    "img": "/telegraf.png",
  },
  */
  {
    "label": "InfluxDB",
    "name": "influxdb",
    "href": "https://influxdb.ufo.k0s.io",
    "img": "/influxdb.svg",
  },
  {
    "label": "Goproxy",
    "name": "goproxy",
    "href": "https://goproxy.ufo.k0s.io",
    "img": "/goproxy.svg",
  },
  {
    "label": "Ntfy",
    "name": "ntfy",
    "href": "https://ntfy.ufo.k0s.io",
    "img": "/ntfy.svg",
  },
  {
    "label": "Ethereum",
    "name": "ethereum",
    "href": "https://ethereum.ufo.k0s.io",
    "img": "/ethereum.svg",
  },
  {
    "label": "Polkadot",
    "name": "polkadot",
    "href": "https://polkadot.ufo.k0s.io",
    "img": "/polkadot.svg",
  },
  {
    "label": "Enjin",
    "name": "enjin",
    "href": "https://enjin.ufo.k0s.io",
    "img": "/enjin.svg",
  },
  {
    "label": "WalletConnect",
    "name": "walletconnect",
    "href": "/#/p/walletconnect",
    "img": "/walletconnect.svg",
  },
  {
    "label": "ConnectRPC",
    "name": "connectrpc",
    "href": "https://buf.ufo.k0s.io",
    "img": "/connectrpc.png",
  },
  {
    "label": "Goma",
    "name": "goma",
    "href": "/#/p/goma",
    "img": "https://xcx-static.yqgz.beijing.gov.cn/images/security/5.png",
  },
  {
    "label": "Bazel",
    "name": "bazel",
    "href": "/#/p/bazel",
    "img": "https://upload.wikimedia.org/wikipedia/en/7/7d/Bazel_logo.svg",
  },
  {
    "label": "Socks5",
    "name": "socks5",
    "href": "/#/p/socks5",
    "img":
      "https://static2.tgstat.ru/channels/_0/b6/b672b623e9a16e53ed3822746eca5f85.jpg",
  },
  {
    "label": "Files",
    "name": "files",
    "href": "/#/p/files",
    "img":
      "https://upload.wikimedia.org/wikipedia/commons/5/53/Windows_Explorer.svg",
  },
  {
    "label": "Jupyter",
    "name": "jupyter",
    "href": "/#/p/jupyter",
    "img": "/jupyter.svg",
  },
  {
    "label": "Caddy",
    "name": "caddy",
    "href": "/#/p/caddy",
    "img": "/caddy.svg",
  },
  {
    "label": "VSCode",
    "name": "vscode",
    "href": "https://vscode.ufo.k0s.io",
    "img": "https://cdn.worldvectorlogo.com/logos/visual-studio-code-1.svg",
  },
  {
    "label": "Jsonl",
    "name": "jsonl",
    "href": "/#/p/jsonl",
    "img":
      "https://upload.wikimedia.org/wikipedia/commons/c/c9/JSON_vector_logo.svg",
  },
  {
    "label": "Grafana",
    "name": "grafana",
    "href": "https://btwiuse.grafana.net",
    "img": "/grafana.svg",
  },
  {
    "label": "Prometheus",
    "name": "prometheus",
    "href": "https://prom.ufo.k0s.io",
    "img": "/prometheus.svg",
  },
  {
    "label": "k16s",
    "name": "k16s",
    "href": "/#/p/k16s",
    "img":
      "https://bafkreia6aekx23kqakddzseb52dj7s5qtwpwzt4cxbl5obuslvbdwe3nmm.ipfs.dweb.link/",
  },
  {
    "label": "Node exporter",
    "name": "node-exporter",
    "href": "https://node-exporter.ufo.k0s.io",
    "img": "/node-exporter.svg",
  },
  {
    "label": "Terminal",
    "name": "terminal",
    "href": "https://wetty.deno.dev",
    "img": "/terminal.svg",
  },
  {
    "label": "Gost",
    "name": "gost",
    "href": "/#/p/gost",
    "img":
      "https://is3-ssl.mzstatic.com/image/thumb/Purple124/v4/99/47/7e/99477ef0-bbbc-9bfb-7c26-408ddfe7234f/source/512x512bb.jpg",
  },
  {
    "label": "Docker",
    "name": "docker",
    "href": "https://docker.ufo.k0s.io",
    "img": "/docker.svg",
  },
  {
    "label": "ChatGPT",
    "name": "chatgpt",
    "href": "https://pipe.im",
    "img": "/openai.svg",
  },
  {
    "label": "Bing",
    "name": "bing",
    "href": "https://bing.ufo.k0s.io",
    "img": "/bing.svg",
  },
  {
    "label": "Google",
    "name": "google",
    "href": "https://google.ufo.k0s.io",
    "img": "/google.svg",
  },
  {
    "label": "DuckDuckGo",
    "name": "duckduckgo",
    "href": "https://duckduckgo.ufo.k0s.io",
    "img": "/duckduckgo.svg",
  },
  {
    "label": "Hacker News",
    "name": "hn",
    "href": "https://hn.ufo.k0s.io",
    "img": "/hn.svg",
  },
  {
    "label": "Kubernetes",
    "name": "kubernetes",
    "href": "https://k8s.ufo.k0s.io",
    "img": "/kubernetes.svg",
  },
  {
    "label": "Tor",
    "name": "tor",
    "href": "/#/p/tor",
    "img": "/tor.svg",
  },
  {
    "label": "Xpra",
    "name": "xpra",
    "href": "/#/p/xpra",
    "img": "/X11.svg",
  },
  {
    "label": "Zot",
    "name": "zot",
    "href": "https://zot.ufo.k0s.io",
    "img": "/zot.svg",
  },
];
/*
  {
    "label": "Project angle",
    "name": "angle",
    "href": "/p/angle",
    "img":
      "https://storage.googleapis.com/chrome-infra/OpenGL%20ES_RGB_June16.svg",
  },
  {
    "label": "Project art",
    "name": "art",
    "href": "/p/art",
    "img":
      "https://storage.googleapis.com/chrome-infra-public/logo/art-logo.png",
  },
  {
    "label": "Project boringssl",
    "name": "boringssl",
    "href": "/p/boringssl",
    "img": "https://storage.googleapis.com/chrome-infra/boringssl-logo.png",
  },
  {
    "label": "Project celab",
    "name": "celab",
    "href": "/p/celab",
    "img": "https://storage.googleapis.com/chrome-infra-public/logo/celab.png",
  },
  {
    "label": "Project chromium",
    "name": "chromium",
    "href": "/p/chromium",
    "img":
      "https://storage.googleapis.com/chrome-infra-public/logo/chromium.svg",
  },
  {
    "label": "Project chromium-website",
    "name": "chromium-website",
    "href": "/p/chromium-website",
    "img":
      "https://storage.googleapis.com/chrome-infra-public/logo/chromium.svg",
  },
  {
    "label": "Project cq-test",
    "name": "cq-test",
    "href": "/p/cq-test",
    "img": "cq",
  },
  {
    "label": "Project crashpad",
    "name": "crashpad",
    "href": "/p/crashpad",
    "img":
      "https://storage.googleapis.com/chrome-infra-public/logo/crashpad-logo.svg",
  },
  {
    "label": "Project crosvm",
    "name": "crosvm",
    "href": "/p/crosvm",
    "img": "cr",
  },
  {
    "label": "Project dart",
    "name": "dart",
    "href": "/p/dart",
    "img":
      "https://storage.googleapis.com/chrome-infra-public/logo/dartlang.png",
  },
  {
    "label": "Project dawn",
    "name": "dawn",
    "href": "/p/dawn",
    "img":
      "https://storage.googleapis.com/chrome-infra-public/logo/dawn-logo.png",
  },
  {
    "label": "Project devtools-frontend",
    "name": "devtools-frontend",
    "href": "/p/devtools-frontend",
    "img":
      "https://storage.googleapis.com/chrome-infra-public/logo/devtools.svg",
  },
  {
    "label": "Project emscripten-releases",
    "name": "emscripten-releases",
    "href": "/p/emscripten-releases",
    "img":
      "https://storage.googleapis.com/chrome-infra-public/logo/emscripten.svg",
  },
  {
    "label": "Project flutter",
    "name": "flutter",
    "href": "/p/flutter",
    "img":
      "https://storage.googleapis.com/chrome-infra-public/logo/flutter-logo.svg",
  },
  {
    "label": "Project fuchsia",
    "name": "fuchsia",
    "href": "/p/fuchsia",
    "img":
      "https://storage.googleapis.com/fuchsia-infra/logo/fuchsia_mark_black.svg",
  },
  {
    "label": "Project gn",
    "name": "gn",
    "href": "/p/gn",
    "img":
      "https://storage.googleapis.com/chrome-infra-public/logo/gn-logo.png",
  },
  {
    "label": "Project goma-client",
    "name": "goma-client",
    "href": "/p/goma-client",
    "img":
      "https://storage.googleapis.com/chrome-infra-public/logo/goma-logo.png",
  },
  {
    "label": "Project goma-server",
    "name": "goma-server",
    "href": "/p/goma-server",
    "img":
      "https://storage.googleapis.com/chrome-infra-public/logo/goma-server-logo.png",
  },
  {
    "label": "Project infra",
    "name": "infra",
    "href": "/p/infra",
    "img":
      "https://storage.googleapis.com/chrome-infra-public/logo/chrome-infra-logo-200x200.png",
  },
  {
    "label": "Project libyuv",
    "name": "libyuv",
    "href": "/p/libyuv",
    "img":
      "https://storage.googleapis.com/chrome-infra-public/logo/libyuv-logo.png",
  },
  {
    "label": "Project nacl",
    "name": "nacl",
    "href": "/p/nacl",
    "img":
      "https://storage.googleapis.com/chrome-infra-public/logo/nacl-logo.png",
  },
  {
    "label": "Project node-ci",
    "name": "node-ci",
    "href": "/p/node-ci",
    "img": "https://storage.googleapis.com/chrome-infra-public/logo/v8.svg",
  },
  {
    "label": "Project open-codecs",
    "name": "open-codecs",
    "href": "/p/open-codecs",
    "img":
      "https://storage.googleapis.com/downloads.webmproject.org/media/images/oc-logo-luci.svg",
  },
  {
    "label": "Project openscreen",
    "name": "openscreen",
    "href": "/p/openscreen",
    "img":
      "https://storage.googleapis.com/chrome-infra-public/logo/openscreen-logo.png",
  },
  {
    "label": "Project pdfium",
    "name": "pdfium",
    "href": "/p/pdfium",
    "img": "https://storage.googleapis.com/chrome-infra/pdfium-logo.png",
  },
  {
    "label": "Project pigweed",
    "name": "pigweed",
    "href": "/p/pigweed",
    "img": "pi",
  },
  {
    "label": "Project r8",
    "name": "r8",
    "href": "/p/r8",
    "img": "r8",
  },
  {
    "label": "Project tint",
    "name": "tint",
    "href": "/p/tint",
    "img": "ti",
  },
  {
    "label": "Project v8",
    "name": "v8",
    "href": "/p/v8",
    "img": "https://storage.googleapis.com/chrome-infra-public/logo/v8.svg",
  },
  {
    "label": "Project webrtc",
    "name": "webrtc",
    "href": "/p/webrtc",
    "img":
      "https://storage.googleapis.com/chrome-infra/webrtc-logo-vert-retro-255x305.png",
  },
*/
