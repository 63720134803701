import React, { createElement as h } from "react";
import { useContext, useRef, useState } from "react";
import { useHub } from "../HubContext";

interface Props {
  className?: string;
}

export default function App({ className = "doc" }: Props) {
  const { hub, hubs, addHub, removeHub } = useHub();
  let hubRef = useRef<HTMLInputElement>(document.createElement("input"));

  let [selected, updateSelected] = useState("https");

  let selectHandler: React.ChangeEventHandler<HTMLSelectElement> = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    updateSelected(event.target.value);
  };

  let handleAdd = () => {
    if (hubRef.current.value && !hubs.hasOwnProperty(hubRef.current.value)) {
      console.log("selected", selected);
      addHub(hubRef.current.value);
      // hub.current.value = "";
    }
  };

  let handleRemove = (key: string) => {
    removeHub(key);
    /*
    if (hubs.hasOwnProperty(key)) {
      delete hubs[key];
    }
    */
  };

  // sort object by second value
  // https://stackoverflow.com/questions/979256/sorting-javascript-object-by-property-value

  const options = [
    { value: "http", label: "http://" },
    { value: "https", label: "https://" },
  ];

  return (
    <div className={className} id="settings">
      <ul>
        {Object.entries(hubs).sort((a, b) => a[1] - b[1]).map(([ihub, _]) => (
          <li key={ihub}>
            {(ihub == hub)
              ? (
                <a onClick={() => alert("cannot remove default hub")}>⭕</a>
              )
              : (
                <a onClick={() => handleRemove(ihub)}>⛔</a>
              )}
            <a href={window.location.origin + `/?hub=${ihub}#/stats`}>{ihub}</a>
          </li>
        ))}
      </ul>
      <select defaultValue={selected} onChange={selectHandler}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <input type="text" ref={hubRef} placeholder={hub} defaultValue={hub} />
      <button onClick={handleAdd}>Add</button>
    </div>
  );
}
