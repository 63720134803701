import React, { createElement as h } from "react";
import AsyncImage from "./AsyncImage";

interface IProject {
  label: string;
  href: string;
  img: string;
  name: string;
}

const App: React.FC<IProject> = ({
  label,
  href,
  img,
  name,
}) => {
  return (
    <a
      href={href}
      aria-label={label}
      className={"project " + (href.startsWith("http") ? "" : "disabled")}
    >
      {!(img.startsWith("http") || img.startsWith("/"))
        ? <div className="project-favicon-text">{img.toUpperCase()}</div>
        : <AsyncImage src={img} />}
      <div className="project-label">{name}</div>
    </a>
  );
};

export default App;
export { IProject };
