import React, { createElement as h } from "react";
import { useEffect } from "react";
import { MetaMaskInpageProvider } from "@metamask/providers";
import { ethers, Signer } from "ethers";
import { stringToHex } from "@polkadot/util";
import { EnsAddr, useHub } from "./HubContext";
import { PublicKey, Transaction, TransactionSignature } from "@solana/web3.js";
import {
  blue,
  cyan,
  green,
  orange,
  purple,
  red,
  yellow,
} from "@mui/material/colors";
import { createTheme, PaletteColor, ThemeProvider } from "@mui/material/styles";
import {
  web3Accounts,
  web3Enable,
  web3FromSource,
} from "@polkadot/extension-dapp";
import type { InjectedAccountWithMeta } from "@polkadot/extension-inject/types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import EthereumProvider from "@walletconnect/ethereum-provider";
import { toast } from "react-toastify";
import { Kubernot } from "./Logo";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    metamask: PaletteColor;
    phantom: PaletteColor;
    polkadot: PaletteColor;
    walletconnect: PaletteColor;
    tronlink: PaletteColor;
    temple: PaletteColor;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    metamask: true;
    phantom: true;
    polkadot: true;
    walletconnect: true;
    tronlink: true;
    temple: true;
  }
}

async function initEthereumProvider() {
  let provider = await EthereumProvider.init({
    projectId: "543e7d3366add361647d6a14f467a39a",
    chains: [1],
    showQrModal: true,
    methods: [
      "eth_requestAccounts",
      "eth_signTransaction",
      "eth_sendTransaction",
      "personal_sign",
      "eth_sign",
      "eth_signTypedData",
    ],
  });
  await provider.connect();
  return provider;
}

let provider: ethers.Eip1193Provider;

const { palette } = createTheme();
const theme = createTheme({
  palette: {
    metamask: palette.augmentColor({ color: red }),
    phantom: palette.augmentColor({ color: purple }),
    polkadot: palette.augmentColor({ color: orange }),
    walletconnect: palette.augmentColor({ color: blue }),
    tronlink: palette.augmentColor({ color: cyan }),
    temple: palette.augmentColor({ color: yellow }),
  },
});

interface PhantomConnectionResponse {
  publicKey: PublicKey;
}

interface TransactionSigned {
  signature: TransactionSignature;
}

interface PhantomWallet {
  connect(): Promise<PhantomConnectionResponse>;
  signAndSendTransaction(transtion: Transaction): Promise<TransactionSigned>;
  signMessage(message: Uint8Array, encoding: string): Promise<Uint8Array>;
  publicKey: PublicKey | null;
}

declare global {
  interface Window {
    ethereum?: MetaMaskInpageProvider;
    solana?: PhantomWallet;
  }
}

async function wc_sign(web3Signer: Signer, address: string) {
  const domain = window.location.hostname;
  const statement = "I accept the terms and wish to sign in with Ethereum";
  const uri = window.location.href;
  const version = 1;
  const chainId = 0;
  const nonce = Math.round(Math.random() * 9999999);
  const issuedAt = Math.floor(Date.now() / 1000);

  const message = `${domain} wants you to sign in with your Ethereum account:
  ${address}

  ${statement}

  URI: ${uri}
  Version: ${version}
  Chain ID: ${chainId}
  Nonce: ${nonce}
  Issued At: ${issuedAt}`;

  const signedMessage = await web3Signer.signMessage(message);

  console.log(signedMessage);
}

async function dot_sign(account: InjectedAccountWithMeta) {
  const domain = window.location.hostname;
  const statement = "I accept the terms and wish to sign in with Polkadot";
  const uri = window.location.href;
  const version = 1;
  const chainId = 0;
  const nonce = Math.round(Math.random() * 9999999);
  const issuedAt = Math.floor(Date.now() / 1000);

  const message = `${domain} wants you to sign in with your Polkadot account:
  ${account.address}

  ${statement}

  URI: ${uri}
  Version: ${version}
  Chain ID: ${chainId}
  Nonce: ${nonce}
  Issued At: ${issuedAt}`;

  const injector = await web3FromSource(account.meta.source);
  const signRaw = injector?.signer?.signRaw;

  if (!signRaw) return;

  const { signature } = await signRaw({
    address: account.address,
    data: message,
    type: "payload",
  });

  const dotResult = signature;
  console.log(dotResult);
}

async function sol_sign(address: string) {
  const domain = window.location.hostname;
  const statement = "I accept the terms and wish to sign in with Solana";
  const uri = window.location.href;
  const version = 1;
  const chainId = 0;
  const nonce = Math.round(Math.random() * 9999999);
  const issuedAt = Math.floor(Date.now() / 1000);

  const message = `${domain} wants you to sign in with your Solana account:
  ${address}
 
  ${statement}

  URI: ${uri}
  Version: ${version}
  Chain ID: ${chainId}
  Nonce: ${nonce}
  Issued At: ${issuedAt}`;

  const encoder = new TextEncoder();
  const msg = encoder.encode(message);
  const solResult = await window.solana?.signMessage(msg, "utf8");
  console.log(solResult);
}

async function eth_sign() {
  const domain = window.location.hostname;
  const statement = "I accept the terms and wish to sign in with Ethereum";
  const uri = window.location.href;
  const version = 1;
  const chainId = 0;
  const nonce = Math.round(Math.random() * 9999999);
  const issuedAt = Math.floor(Date.now() / 1000);

  const message = `${domain} wants you to sign in with your Ethereum account:
  ${window.ethereum?.selectedAddress}

  ${statement}

  URI: ${uri}
  Version: ${version}
  Chain ID: ${chainId}
  Nonce: ${nonce}
  Issued At: ${issuedAt}`;

  const msg = stringToHex(message);
  const ethResult = await window.ethereum?.request({
    method: "personal_sign",
    params: [window.ethereum?.selectedAddress, msg],
  });
  console.log(ethResult);
}

interface Props {
  className: string;
  isLogin: boolean;
}

export function App(props: Props) {
  const { className, isLogin } = props;
  const { agents, wc, dot, ens, sol, login, logout, hub, wsState, wsError } =
    useHub();

  const notify = (info: string) =>
    toast.info(info, {
      position: "top-center",
      autoClose: 1000,
      theme: "dark",
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  // TODO: update selected address on change
  // atm it won't take effect until refresh
  // need a better way to to this
  useEffect(() => {
    let id: ReturnType<typeof setInterval>;
    async function checkAddrChange() {
      if (ens) {
        let ens2 = window.ethereum?.selectedAddress;
        if (ens2 && (ens != ens2)) {
          console.log("logout::changed::ens", ens, ens2);
          clearInterval(id);
          logout();
        }
      }
      if (sol) {
        let sol2 = window.solana?.publicKey?.toString();
        if (sol2 && (sol2 != sol)) {
          console.log("logout::changed::sol", sol, sol2);
          clearInterval(id);
          logout();
        }
      }
    }

    id = setInterval(checkAddrChange, 1000);
  });

  const onDisconnect = async () => {
    logout();
    if (wc && provider) {
      // await provider.close();
    }
  };

  const onConnectMetamask = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.enable();
        if (window.ethereum.selectedAddress) {
          // login(window.ethereum.selectedAddress);
          // TODO: verify signature is ok
          let ensAddr: EnsAddr = { ens: window.ethereum!.selectedAddress! };
          eth_sign().then(() => login(ensAddr));
        }
      } catch (error) {
        notify("User denied account access...");
      }
    } else {
      notify(
        "Non-Ethereum browser detected. You should consider trying MetaMask!",
      );
    }
  };

  const onConnectPhantom = async () => {
    if (window.solana) {
      try {
        const resp = await window.solana.connect();
        if (resp.publicKey.toString()) {
          // login(window.ethereum.selectedAddress);
          // TODO: verify signature is ok
          sol_sign(resp.publicKey.toString()).then(() =>
            login({ sol: resp.publicKey.toString() })
          );
        }
      } catch (error) {
        notify("User denied account access...");
      }
    } else {
      notify(
        "Non-Solana browser detected. You should consider trying Phantom!",
      );
    }
  };

  const onConnectPolkadot = async () => {
    const exts = await web3Enable("k0s");
    if (exts.length === 0) {
      notify(
        "Non-Polkadot browser detected. You should consider trying Polkadot.js extension!",
      );
      return;
    }
    const accounts = await web3Accounts();
    console.log(accounts);
    if (accounts.length == 0) return;
    const account = accounts[0];
    dot_sign(account).then(() => login({ dot: account.address }));
  };

  const onWalletConnect = async () => {
    try {
      provider = await initEthereumProvider();
      const web3Provider = new ethers.BrowserProvider(provider);
      const web3Signer = await web3Provider.getSigner();

      const address = await web3Signer.getAddress();
      console.log(address);
      wc_sign(web3Signer, address).then(() => login({ wc: address }));
    } catch (e) {
      console.log(e);
    }
  };

  const onTodo = async () => {
    notify("Not supported yet");
  };

  return (
    <div className={className} id="login">
      <div className="lucy-logo">
        {Kubernot}
      </div>
      <div>
        <a href="https://github.com/btwiuse/k0s"></a>
        <br />
        <br />
        <br />
        built:{" "}
        <b>
          <span className="local-time " data-timestamp="1657536267333">
            Monday, 11-Jul-22 10:44:27 UTC
          </span>
        </b>
        <br />
        version: <b>11620-32edf62</b>
        <br />
        hub: <b>{hub}</b>
        <br />
        agents: <b>{agents.length}</b>
        <br />
        wsState: <b>{wsState}</b>
        <br />
        <br />
      </div>
      <ThemeProvider theme={theme}>
        {isLogin
          ? (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(1, 1fr)",
                gap: 1,
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={onDisconnect}
              >
                Disconnect
              </Button>
            </Box>
          )
          : (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: 1,
              }}
            >
              <Button
                variant="contained"
                color="metamask"
                onClick={onConnectMetamask}
              >
                Connect MetaMask
              </Button>
              <Button
                variant="contained"
                color="phantom"
                onClick={onConnectPhantom}
              >
                Connect Phantom
              </Button>
              <Button
                variant="contained"
                color="polkadot"
                onClick={onConnectPolkadot}
              >
                Connect Polkadot
              </Button>
              <Button
                variant="contained"
                color="walletconnect"
                onClick={onWalletConnect}
              >
                WalletConnect V2
              </Button>
              <Button
                variant="contained"
                color="tronlink"
                onClick={onTodo}
              >
                Connect Tron
              </Button>
              <Button
                variant="contained"
                color="temple"
                onClick={onTodo}
              >
                Connect Tezos
              </Button>
            </Box>
          )}
      </ThemeProvider>
    </div>
  );
}

export default React.memo(App);
